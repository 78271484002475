export const estilosProv = {
  41: {
    fondo: "#0046ac",
    fontColor: "#f0f0f0",
    fondoLlamador: "#0046ac",
    gradiente1Llamador: "",
    gradiente2Llamador: "",
    secundaryColor: "#ee7511",
  },
  11: {
    fondo: "#0046ac",
    fontColor: "#f0f0f0",
    fondoLlamador: "#0046ac",
    gradiente1Llamador: "",
    gradiente2Llamador: "",
    secundaryColor: "#ee7511",
  },
  42: {
    fondo: "#ffcc00",
    fontColor: "#020200",
    fondoLlamador: "#ffcc00",
    gradiente1Llamador: "#ffeea1",
    gradiente2Llamador: "#2122411f",
    secundaryColor: "#ee7511",
  },
  12: {
    fondo: "#ffcc00",
    fontColor: "#020200",
    fondoLlamador: "#ffcc00",
    gradiente1Llamador: "#ffeea1",
    gradiente2Llamador: "#2122411f",
    secundaryColor: "#ee7511",
  },
  53: {
    fondo: "#5601b7",
    fontColor: "#f0f0f0",
    fondoLlamador: "#5601b7",
    gradiente1Llamador: "#ffe3cf",
    gradiente2Llamador: "#ffe3cf",
    secundaryColor: "#ee7511",
  },
  23: {
    fondo: "#5601b7",
    fontColor: "#f0f0f0",
    fondoLlamador: "#5601b7",
    gradiente1Llamador: "#ffe3cf",
    gradiente2Llamador: "#ffe3cf",
    secundaryColor: "#ee7511",
  },
  43: {
    fondo: "#01C160",
    fontColor: "#f0f0f0",
    fondoLlamador: "#19193a",
    gradiente1Llamador: "#aeaeef",
    gradiente2Llamador: "#5e5ef7",
    secundaryColor: "#ee7511",
  },
  13: {
    fondo: "#01C160",
    fontColor: "#f0f0f0",
    fondoLlamador: "#19193a",
    gradiente1Llamador: "#aeaeef",
    gradiente2Llamador: "#5e5ef7",
    secundaryColor: "#ee7511",
  },
  20: {
    fondo: "#c20d19",
    fontColor: "#fff",
    fondoLlamador: "#feee00",
    gradiente1Llamador: "#feee0073",
    gradiente2Llamador: "#02020257",
    secundaryColor: "#ee7511",
  },
  50: {
    fondo: "#c20d19",
    fontColor: "#fff",
    fondoLlamador: "#feee00",
    gradiente1Llamador: "#feee0073",
    gradiente2Llamador: "#02020257",
    secundaryColor: "#ee7511",
  },
  18: {
    fondo: "#3B9FA7",
    fontColor: "#fff",
    fondoLlamador: "transparent",
    gradiente1Llamador: "transparent",
    gradiente2Llamador: "transparent",
    secundaryColor: "#ee7511",
  },
  48: {
    fondo: "#3B9FA7",
    fontColor: "#fff",
    fondoLlamador: "transparent",
    gradiente1Llamador: "transparent",
    gradiente2Llamador: "transparent",
    secundaryColor: "#ee7511",
  },
};
