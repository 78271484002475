import React from "react";
import { useState } from "react";
import $ from "jquery";
import "jquery-confirm";
import ReactGA from "react-ga";
import { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import FormDatosSeguridad from "./registrarse/FormDatosSeguridad";
import FormDatosContacto from "./registrarse/FormDatosContacto";
import FormDatospersonales from "./registrarse/FormDatospersonales";
import { withRouter } from "react-router-dom";
import EncabezaoRegistro from "./registrarse/EncabezaoRegistro";
import { Form } from "reactstrap";
import ModalAgencias from "./registrarse/tools/ModalAgencias";
import ModalTerminosCondiciones from "./registrarse/tools/ModalTerminosCondiciones";
import ModalSubAg from "./registrarse/tools/ModalSubAg";
import RegistroSatisfactorio from "./registrarse/RegistroSatisfactorio";
import { CargandoSpinner } from "./tools/CargandoSpinner";

export const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  return isDesktop ? children : null;
};
export const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1023 });
  return isTablet ? children : null;
};
export const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};
export const Default = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 });
  return isNotMobile ? children : null;
};

const Registrarse = (props) => {
  const [step1, setstep1] = useState(true);
  const [step2, setstep2] = useState(false);
  const [step3, setstep3] = useState(false);
  const [anim2In, setanim2In] = useState("");
  const [anim2Out, setanim2Out] = useState("");
  const [viewTerms, setviewTerms] = useState(false);
  const [pass1, setpass1] = useState(false);
  const [pass2, setpass2] = useState(false);
  const [clave1, setclave1] = useState("");
  const [clave2, setclave2] = useState("");
  const [fetching, setfetching] = useState(true);
  const [nombre, setnombre] = useState("");
  const [apellido, setapellido] = useState("");
  const [genero, setgenero] = useState("M");
  const [diaFecNac, setdiaFecNac] = useState("");
  const [anioFecNac, setanioFecNac] = useState("");
  const [mesFecNac, setMesFecNac] = useState("0");
  const [nroDocuReg, setnroDocuReg] = useState("");
  const [cuil, setCuil] = useState("");
  const [agencia, setagencia] = useState(null);
  const [provincia, setprovincia] = useState(null);
  const [direccion, setdireccion] = useState("");
  const [localidad, setlocalidad] = useState(undefined);
  const [correo, setcorreo] = useState("");
  const [telefono, settelefono] = useState("");
  const [aceptarTerminos, setaceptarTerminos] = useState(false);
  const [provincias, setprovincias] = useState([]);
  const [agencias, setagencias] = useState([]);
  const [registrado, setregistrado] = useState(false);
  const [errores, seterrores] = useState([]);
  const [captchaVerificado, setcaptchaVerificado] = useState(false);
  const [listaLocalidades, setlistaLocalidades] = useState([]);
  const [localidades, setlocalidades] = useState([]);
  const [hashAgencias, sethashAgencias] = useState({});
  const [listaSubAgencias, setlistaSubAgencias] = useState(null);
  const [agenciaSeleccionada, setagenciaSeleccionada] = useState(null);
  const [estadoRegist, setestadoRegist] = useState("INICIO");
  const [refresh, setrefresh] = useState(null);
  const [tyc, settyc] = useState(null);
  const [logoHeader, setlogoHeader] = useState(null);
  const [includeSubAg, setincludeSubAg] = useState(null);
  const [size, setSize] = useState($(window).width());
  const [animacion, setAnimacion] = useState($(window).width());
  const [requiereGeo, setrequiereGeo] = useState(false);
  const [latitud, setlatitud] = useState(undefined);
  const [longitud, setlongitud] = useState(undefined);
  const [errors, seterrors] = useState({
    nombre: "",
    apellido: "",
    genero: "",
    fecNac: "",
    nroDocuReg: "",
    agencia: "",
    provincia: "",
    direccion: "",
    localidad: "",
    correo: "",
    telefono: "",
    clave: "",
    confirmaClave: "",
    aceptarTerminos: "",
    captcha: "",
    disabled: false,
    cuil: "",
  });
  const cargarImgDinamica = (nombre) => {
    import(
      `./img/${nombre}_${process.env.REACT_APP_COD_ORGANIZACION}.png`
    ).then((image) => {
      setlogoHeader(image.default);
    });
  };

  const blurKeyboard = (e) => {
    //e.target.blur()
  };

  const handleSlide = () => {
    if (step1) {
      setstep1(false);
      setstep2(true);
    } else {
      setstep1(false);
      setstep2(false);
      setstep3(true);
    }
  };

  const handleSlideBack = () => {
    if (step2) {
      setstep2(false);
      setstep1(true);
    } else {
      setstep3(false);
      setstep2(true);
    }
  };

  const handlePassView = () => {
    let x = $("#clave");
    if (x.hasClass("text-pass")) {
      x.removeClass("text-pass");
    } else {
      x.addClass("text-pass");
    }
    setpass1(!pass1);
  };

  const handlePassView2 = () => {
    let x = $("#confirmaClave");
    if (x.hasClass("text-pass")) {
      x.removeClass("text-pass");
    } else {
      x.addClass("text-pass");
    }
    setpass2(!pass2);
  };

  const handleNotificarCorreo = () => {
    var check = document.getElementById("notificacionCorreo");
  };

  const ordenarAgencias = () => {
    return function (a, b) {
      var aLocalidad = a.localidad.toUpperCase();
      var bLocalidad = b.localidad.toUpperCase();
      var aDescripcion = a.descripcion.toUpperCase();
      var bDescripcion = b.descripcion.toUpperCase();

      // eslint-disable-next-line eqeqeq
      if (aLocalidad == bLocalidad) {
        if (!isNaN(a.descripcion)) aDescripcion = Number(a.descripcion);
        if (!isNaN(b.descripcion)) bDescripcion = Number(b.descripcion);
        return aDescripcion < bDescripcion
          ? -1
          : aDescripcion > bDescripcion
          ? 1
          : 0;
      } else {
        return aLocalidad < bLocalidad ? -1 : 1;
      }
    };
  };

  const seleccionaTuAgencia = () => {
    // Armar listas de Objetos de localidades con sus agencias
    let agenciaslocal = agencias;
    let agenciasOrd = [];
    if (agenciaslocal != null)
      agenciasOrd = agenciaslocal.sort(ordenarAgencias());

    let aHashAgencias = new Object();
    let aListaLocalidades = [];

    let i = 0;
    for (i = 0; i < agenciasOrd.length; ++i) {
      if (aHashAgencias[agenciasOrd[i].localidad] === undefined) {
        aHashAgencias[agenciasOrd[i].localidad] = new Array();
        aListaLocalidades.push({ localidad: agenciasOrd[i].localidad });
      }
      let arrayAgencias = aHashAgencias[agenciasOrd[i].localidad];
      arrayAgencias.push(agenciasOrd[i]);
    }

    let aErrors = errors;
    aErrors.agencia = "";
    seterrors(aErrors);
    setlistaLocalidades(aListaLocalidades);
    sethashAgencias(aHashAgencias);
  };

  const handleAgencia = (agencia) => {
    setagencia(agencia.codigo);
    setagenciaSeleccionada({
      descAgencia: agencia.descripcion,
      direccion: agencia.direccion,
      localidad: agencia.localidad,
    });
    $("#agenciaModal").modal("toggle");
    $(".modal-backdrop").remove();
  };

  const handleSubAgencia = (agencia) => {
    const encodedValue = process.env.REACT_APP_COD_ORGANIZACION_FETCH;
    let codigoAgencia = agencia.codigo;

    let url =
      process.env.REACT_APP_WS_LISTADO_SUBAGENCIAS +
      "?codigoOrganizacion=" +
      encodedValue +
      "&codigoAgencia=" +
      codigoAgencia;

    let statusCode = "";

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((respPromise) => {
        statusCode = respPromise.status;

        if (respPromise.status == process.env.REACT_APP_CODIGO_CERRO_SESION) {
          props.cerrarSesion();
          props.history.push("/");
        }
        return respPromise.json();
      })
      .then((json) => {
        if (json.status === "ok") {
          $("#subAgModal").modal("toggle");
          setlistaSubAgencias(json);
        } else if (json.status === "error") {
          props.mensajeErrorWS("Registración", json.errores);
          props.history.push("/");
        }
      })
      .catch((error) => {
        props.mensajeErrorGeneral(error.message);
        props.history.push("/");
      });
  };

  const setFocusStep1 = () => {
    setstep1(true);
    setstep2(false);
    setstep3(false);
  };
  const setFocusStep2 = () => {
    setstep2(true);
    setstep1(false);
    setstep3(false);
  };
  const setFocusStep3 = () => {
    setstep3(true);
    setstep1(false);
    setstep2(false);
  };
  const setFocusStep1AndSlideLeft = () => {
    setFocusStep2();
  };

  const setFocus = () => {
    setAnimacion("animate__animated animate__backOutRight");

    if (errors.nombre !== "") setFocusStep1();
    else if (errors.apellido !== "") setFocusStep1();
    else if (errors.cuil !== "") setFocusStep1();
    else if (errors.fecNac !== "") setFocusStep1();
    else if (errors.agencia !== "") setFocusStep1AndSlideLeft();
    else if (errors.direccion !== "") setFocusStep1AndSlideLeft();
    else if (errors.correo !== "") setFocusStep1AndSlideLeft();
    else if (errors.telefono !== "") setFocusStep1AndSlideLeft();
  };

  useEffect(() => {
    cargarImgDinamica("LOGO_APP", "VERTICAL_ORG", "HORIZONTAL_ORG");

    $(".pageFlow-reg").scrollTop(0);
    $("#nombre").focus();
    props.logCurrentPage("registrarse");

    ReactGA.pageview("/IngresoRegistracion");

    inicializar();

    props.actuVersionSiCorresponde(props);
    /*
    if (this.recaptchaInstance) {
      this.recaptchaInstance.reset();
      setcaptchaVerificado(false);
    }
    */
  }, []);

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setSize(newWidth);
    };

    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  useEffect(() => {
    if (diaFecNac !== "" || mesFecNac !== "0" || anioFecNac !== "") {
      validarFecha(diaFecNac, mesFecNac, anioFecNac);
    }
  }, [diaFecNac, mesFecNac, anioFecNac]);

  useEffect(() => {
    if (requiereGeo) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          setlatitud(position.coords.latitude.toString());
          setlongitud(position.coords.longitude.toString());
        },
        function (error) {
          console.error("Error Code = " + error.code + " - " + error.message);
        }
      );
    }
  }, [requiereGeo]);

  const volverHOME = () => {
    props.history.push("/");
  };

  const validateForm = () => {
    let valid = true;

    let errorslocal = errors;

    if (nombre === "") errorslocal.nombre = "Ingresar el nombre";
    if (apellido === "") errorslocal.apellido = "Ingresar el apellido";
    else {
      ReactGA.pageview("/CargoApellido");
    }

    if (genero === "") errorslocal.nombre = "Seleccionar Masculino/Femenino";

    if (anioFecNac === "" || diaFecNac === "")
      errorslocal.fecNac = "Ingresar fecha nacimiento";

    if (cuil === "") errorslocal.cuil = "Ingresar número de CUIL";
    else {
      ReactGA.pageview("/CargoCUIL");
    }

    if (agenciaSeleccionada === null)
      errorslocal.agencia = "Seleccionar una agencia";

    if ($("#provincia").val() === 0)
      errorslocal.provincia = "Ingresar provincia";

    if (direccion === "") errorslocal.direccion = "Ingresar dirección";

    if (localidad === undefined) errorslocal.localidad = "Ingresar localidad";

    if (correo === "") errorslocal.correo = "Ingresar correo";

    if (telefono === "") errorslocal.telefono = "Ingresar teléfono";

    if (clave1 === "") errorslocal.clave = "Ingresar clave";

    if (clave2 === "")
      errorslocal.confirmaClave = "Ingresar confirmación clave";

    if ($("#aceptarTerminos").prop("checked") === false)
      errorslocal.aceptarTerminos = "No aceptó los terminos y condiciones";

    let i = 0;

    let errores = Object.values(errors);
    if (errores) {
      for (i = 0; i < errores.length; ++i) {
        if (errores[i].length > 0) {
          valid = false;
          break;
        }
      }
    }

    if (
      captchaVerificado === false &&
      process.env.REACT_APP_ACTIVAR_CAPTCHA_REGISTRACION === "si"
    ) {
      errorslocal.captcha = "Debes indicar que no eres un robot";
      if (valid) {
        valid = false;
        props.mensajeErrorWS("Indique que no es un Robot", [
          {
            codigo: "",
            error: "Debe indicar que no es un robot para registrarse",
          },
        ]);
      }
    }
    seterrors(errorslocal);
    setFocus();

    return valid;
  };

  const handleSubmit = (event) => {
    if ((latitud === undefined || longitud === undefined) && requiereGeo) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          setlatitud(position.coords.latitude.toString());
          setlongitud(position.coords.longitude.toString());
        },
        function (error) {
          props.mensajeErrorGeneral(
            "Debes activar los permisos de localizacion para poder registrarte"
          );
          return;
        }
      );
    }
    if (errors.disabled) {
      return;
    }
    seterrors({ ...errors, disabled: true });

    event.preventDefault();
    if (validateForm()) {
      registrarse();
      seterrors({ ...errors, disabled: false });
    } else {
      seterrors({ ...errors, disabled: false });
      //setState({});
    }
  };

  const registrarse = () => {
    grabarRegistracion();
  };

  const inicializar = () => {
    setfetching(true);
    const encodedValue = process.env.REACT_APP_COD_ORGANIZACION_FETCH;

    let url =
      process.env.REACT_APP_WS_INICIALIZAR_REGISTRACION +
      "?codigoOrganizacion=" +
      encodedValue;

    let statusCode = "";

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((respPromise) => {
        statusCode = respPromise.status;

        if (respPromise.status == process.env.REACT_APP_CODIGO_CERRO_SESION) {
          props.cerrarSesion();
          props.history.push("/");
        }
        return respPromise.json();
      })
      .then((json) => {
        if (json.status === "ok") {
          setagencias(json.agencias);
          seterrores(json.errores);
          setincludeSubAg(json.includeSubAg);
          setlocalidades(json.localidades);
          setprovincia(json.provincias);
          settyc(json.tyc);
          setrequiereGeo(json.requiereGeo);
          setfetching(false);
        } else if (json.status === "error") {
          props.mensajeErrorWS("Registración", json.errores);
          props.history.push("/");
        }
      })
      .catch((error) => {
        props.mensajeErrorGeneral(error.message);
        props.history.push("/");
      });
  };

  const tratamientoErrores = (errores) => {
    let i;
    let mensajesGenerales = [];
    if (!errores) return;
    for (i = 0; i < errores.length; ++i) {
      if ("undefined" != typeof errors[errores[i].codigo]) {
        errors[errores[i].codigo] = errores[i].error;
      } else {
        mensajesGenerales.push(errores[i]);
      }
    }

    setFocus();
    setrefresh(Math.random());

    if (mensajesGenerales.length > 0)
      props.mensajeErrorWS("Problema al registrarse", mensajesGenerales);
  };

  const grabarRegistracion = () => {
    let fecNac = new Date(anioFecNac, mesFecNac, diaFecNac);
    let fecNacString =
      fecNac.getFullYear() +
      "-" +
      ("0" + (fecNac.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + fecNac.getDate()).slice(-2);

    let aNotificacionPorCorreo = false;
    if ($("#notificacionCorreo:checkbox:checked").length === 0)
      aNotificacionPorCorreo = false;
    else aNotificacionPorCorreo = true;

    let parametros = {
      codigoOrganizacion: process.env.REACT_APP_COD_ORGANIZACION_FETCH,
      nombre: nombre,
      apellido: apellido,
      genero: genero,
      fechaNacimiento: fecNacString,
      cuil: cuil,
      documento: parseInt(cuil.slice(3, -1), 10),
      direccion: direccion,
      localidadId: localidad,
      email: correo,
      nroTelefono: telefono,
      password: clave1,
      matchingPassword: clave2,
      tipoDocumento: "DNI",
      recibeMsjEmail: aNotificacionPorCorreo,
      agencia: agencia,
      tycId: tyc !== null ? tyc.id : null,
    };
    if (requiereGeo) {
      parametros.latitud = latitud;
      parametros.longitud = longitud;
    }

    let url = process.env.REACT_APP_WS_REGISTRACION;

    let statusCode = "";

    setestadoRegist("EJECUTANDO");

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(parametros),
    })
      .then((respPromise) => {
        seterrors({ ...errors, disabled: false });
        setestadoRegist("FIN");

        statusCode = respPromise.status;

        if (respPromise.status == process.env.REACT_APP_CODIGO_CERRO_SESION) {
          props.cerrarSesion();
          props.history.push("/");
        }
        return respPromise.json();
      })
      .then((json) => {
        if (json.status === "ok") {
          seterrors({ ...errors, disabled: false });
          setestadoRegist("FIN");
          seterrores([]);
          setregistrado(true);
          setnombre(json.nombre);
          setcorreo(json.correo);
        } else if (json.status === "error") {
          seterrors({ ...errors, disabled: false });
          setestadoRegist("FIN");
          seterrores(json.errores);
          setregistrado(false);
          tratamientoErrores(json.errores);
        }
      })
      .catch((error) => {
        seterrors({ ...errors, disabled: false });
        setestadoRegist("FIN");
        props.mensajeErrorGeneral(error.message);
      });
  };

  const handleClickTerminos = (event) => {
    if (!viewTerms) {
      $("#terminosModal").modal("show");
    }
    seterrors({ ...errors, aceptarTerminos: "" });
  };

  const validarFecha = (dia, mes, anio) => {
    let errorslocal = errors;
    let fechaOk = false;
    let laFecNac = new Date(anio, mes, dia, 0, 0, 0, 0);
    //
    //
    //
    //
    //
    //
    if (
      laFecNac.getDate() == dia &&
      laFecNac.getMonth() == mes &&
      laFecNac.getFullYear() == anio
    ) {
      fechaOk = true;
    }
    if (fechaOk === false || calculate_age(laFecNac) > 130) {
      seterrors({ ...errors, fecNac: "Fecha nacimiento inválida" });
    } else {
      let res = calculate_age(laFecNac) < 18 ? "Menor de 18 años" : "";
      seterrors({ ...errors, fecNac: res });
    }
  };

  const handleChangeCuil = () => {
    // eslint-disable-next-line no-use-before-define
    let cuilJ = $("#cuil").val().trim();
    let arrayCuil = cuilJ.split("");
    arrayCuil.splice(2, 0, "-");
    arrayCuil.splice(-1, 0, "-");
    cuilJ = arrayCuil.join("");

    setCuil(cuilJ);
    let errorslocal = errors;
    errorslocal.cuil = cuilJ.length < 11 ? "Mínimo 11 números" : "";
    seterrors(errorslocal);
  };

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errorslocal = errors;

    switch (name) {
      case "nombre":
        errorslocal.nombre = value.length < 1 ? "Debe ingresar el nombre" : "";
        break;
      case "apellido":
        errorslocal.apellido =
          value.length < 1 ? "Debe ingresar el apellido" : "";
        break;
      case "direccion":
        errorslocal.direccion =
          value.length < 1 ? "Debe ingresar la dirección" : "";
        break;
      case "RegDia":
      case "RegMes":
      case "RegAnio":
        validarFecha();
        break;
      case "cuil":
        errorslocal.cuil =
          value.toString().trim().length < 13 ? "Mínimo 11 números" : "";
        break;
      case "provincia":
        errorslocal.provincia = value <= 0 ? "Seleccionar una provincia" : "";
        break;
      case "agencia":
        errorslocal.agencia =
          value === "SIN_AGENCIA" ? "Seleccionar una agencia" : "";
        break;
      case "localidad":
        errorslocal.localidad =
          value.length < 1 ? "Debe ingresar la localidad" : "";
        break;
      case "telefono":
        errorslocal.telefono =
          value.length < 1 ? "Debe ingresar el teléfono" : "";
        break;
      case "correo":
        errorslocal.correo = document.getElementById("correo").validity.valid
          ? ""
          : "Correo inválido";
        break;
      case "clave":
        if (value === "") {
          $("#pasLabel").removeClass("hide");
        } else {
          $("#pasLabel").addClass("hide");
        }
        errorslocal.clave =
          value.length < 8
            ? "La clave debe contener al menos 8 caracteres"
            : "";
        errorslocal.confirmaClave =
          value !== "" && value !== $("#confirmaClave").val()
            ? "No coinciden las claves"
            : "";
        break;
      case "confirmaClave":
        if (value === "") {
          $("#pasLabel2").removeClass("hide");
        } else {
          $("#pasLabel2").addClass("hide");
        }
        errorslocal.confirmaClave =
          value !== "" && value !== $("#clave").val()
            ? "No coinciden las claves"
            : "";
        break;
      case "aceptarTerminos":
        errorslocal.aceptarTerminos = "";
        break;

      default:
        break;
    }

    seterrors(errorslocal);
  };

  const calculate_age = (birthday) => {
    var today = new Date();
    var birthDate = new Date(birthday);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() <= birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const TerminosCondicionesTemplate = require(process.env
    .REACT_APP_PAGE_TERM_COND).default;

  return (
    <>
      <div className="wrapper pageFlow-reg">
        <div className="form-wrapper">
          <EncabezaoRegistro
            step1={step1}
            step2={step2}
            step3={step3}
            logoHeader={logoHeader}
            volverHOME={volverHOME}
            size={size}
            setFocusStep1={setFocusStep1}
            setFocusStep2={setFocusStep2}
            setFocusStep3={setFocusStep3}
            setAnimacion={setAnimacion}
          />
          {registrado && <RegistroSatisfactorio correo={correo} />}

          {!registrado && (
            <div className="container-fluid">
              <div className="row d-flex justify-content-center">
                <Form
                  autoComplete="off"
                  style={
                    navigator.userAgent.toLowerCase().includes("apple")
                      ? { marginTop: "3vh" }
                      : { paddingBottom: "7rem" }
                  }
                  id="registrarse"
                  className="w-90 mx-auto pt-3 signin"
                  onSubmit={handleSubmit}
                  noValidate
                >
                  <div id="form" className="desktopTotalForm">
                    <div className="text-dark text-center">
                      {step1 && (
                        <FormDatospersonales
                          nombre={nombre}
                          setnombre={setnombre}
                          apellido={apellido}
                          setapellido={setapellido}
                          step1={step1}
                          diaFecNac={diaFecNac}
                          setdiaFecNac={setdiaFecNac}
                          anioFecNac={anioFecNac}
                          setanioFecNac={setanioFecNac}
                          setMesFecNac={setMesFecNac}
                          mesFecNac={mesFecNac}
                          errors={errors}
                          seterrors={seterrors}
                          cuil={cuil}
                          setCuil={setCuil}
                          nroDocuReg={nroDocuReg}
                          setnroDocuReg={setnroDocuReg}
                          handleSlide={handleSlide}
                          handleChangeCuil={handleChangeCuil}
                          handleChange={handleChange}
                          blurKeyboard={blurKeyboard}
                          validarFecha={validarFecha}
                          size={size}
                          animacion={animacion}
                          setAnimacion={setAnimacion}
                          setgenero={setgenero}
                        />
                      )}
                      {step2 && (
                        <FormDatosContacto
                          provincias={provincias}
                          localidades={localidades}
                          localidad={localidad}
                          setlocalidad={setlocalidad}
                          direccion={direccion}
                          setdireccion={setdireccion}
                          correo={correo}
                          setcorreo={setcorreo}
                          anim2In={anim2In}
                          anim2Out={anim2Out}
                          step2={step2}
                          errors={errors}
                          telefono={telefono}
                          settelefono={settelefono}
                          agenciaSeleccionada={agenciaSeleccionada}
                          handleChange={handleChange}
                          seleccionaTuAgencia={seleccionaTuAgencia}
                          handleSlideBack={handleSlideBack}
                          handleSlide={handleSlide}
                          size={size}
                          animacion={animacion}
                          setAnimacion={setAnimacion}
                        />
                      )}

                      {step3 && (
                        <FormDatosSeguridad
                          step3={step3}
                          pass1={pass1}
                          clave1={clave1}
                          setclave1={setclave1}
                          clave2={clave2}
                          setclave2={setclave2}
                          setpass1={setpass1}
                          errors={errors}
                          pass2={pass2}
                          setpass2={setpass2}
                          handlePassView={handlePassView}
                          handleChange={handleChange}
                          handlePassView2={handlePassView2}
                          handleNotificarCorreo={handleNotificarCorreo}
                          handleSlideBack={handleSlideBack}
                          handleSubmit={handleSubmit}
                          handleClickTerminos={handleClickTerminos}
                          viewTerms={viewTerms}
                          size={size}
                          animacion={animacion}
                          setAnimacion={setAnimacion}
                          aceptarTerminos={aceptarTerminos}
                          setaceptarTerminos={setaceptarTerminos}
                        />
                      )}
                    </div>
                  </div>
                </Form>
              </div>

              <ModalTerminosCondiciones
                fetching={fetching}
                tyc={tyc}
                TerminosCondicionesTemplate={TerminosCondicionesTemplate}
              />
              <ModalAgencias
                listaLocalidades={listaLocalidades}
                includeSubAg={includeSubAg}
                hashAgencias={hashAgencias}
                handleAgencia={handleAgencia}
                handleSubAgencia={handleSubAgencia}
              />
              <ModalSubAg
                listaSubAgencias={listaSubAgencias}
                handleAgencia={handleAgencia}
              />
            </div>
          )}
        </div>
      </div>
      {estadoRegist === "EJECUTANDO" && <CargandoSpinner />}
    </>
  );
};

export default withRouter(Registrarse);
